<template>
  <section>
    <side class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Jalousien / Außenraffstores <br />
    </side>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h1 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Eurosun - Außenraffstores
      </h1>
      <div class="grid grid-cols-2">
        <figure class="col-span-full lg:col-span-1">
          <img
            src="../assets/img/eurosun/außenraffstore/eurosun-außenraffstore.webp"
            alt=" berliner eurosun außenraffstore"
            class="md:h-96 w-full"
          />
        </figure>

        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Außenraffstores in der konventionellen Standardmontage
          </h2>
          <div class="px-5 w-full md:pl-10 md:pb-3">
            Die Außenraffstores EUROSUN stellen die qualitative Lösung für außen
            liegende Sonnenschutztechnik dar, wobei alle Lamellen aus
            hochwertigen Aluminium bestehen.<br /><br />
            <h3 class="py-3 text-2xl">Vorteile:</h3>

            <i class="fas fa-caret-right"></i> Kein lästiges Aufheizen von Wohn-
            und Büroräumen<br />
            <i class="fas fa-caret-right"></i> Reduzierung der störenden Refl
            exionen auf Bildschirmen<br />
            <i class="fas fa-caret-right"></i> Schutz der Privatsphäre vor
            neugierigen Blicken<br />
            <i class="fas fa-caret-right"></i> Minimierung von einfallendem
            Straßenlicht in Schlafräume<br />
            <i class="fas fa-caret-right"></i> Verhindert Ausbleichen bei
            Teppichen und Mobiliar<br />
            <i class="fas fa-caret-right"></i> Einsparung von Energiekosten<br />
          </div>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Lamellenangebot">
            <li class="flex flex-col md:flex-row p-4">
              <figure class="p-1 w-full md:w-1/3">
                <img
                  src="../assets/img/eurosun/außenraffstore/eurosun-flachlamelle.webp"
                  alt="berliner flachlamelle"
                />
              </figure>
              <p class="text-lg self-center w-full md:w-2/3">
                <span class="font-bold"
                  >Flachlamellen in den Breiten 50, 60, 80 und 100mm</span
                >
                <br />
                Flachlamellen bieten ein zeitloses klassisches Design und
                zeichnen sich besonders durch eine sehr geringe Pakethöhe aus.
                Die Lamellen sind zwischen den Querstegen der Leiterkordeln
                eingeschoben, wobei die Lamellen mit den Querstegen verbunden
                sind.
              </p>
            </li>
            <li class="flex flex-col md:flex-row p-4">
              <div class="p-1 w-full md:w-1/3">
                <img
                  src="../assets/img/eurosun/außenraffstore/eurosun-geboerdelte-lamellen.webp"
                  alt=" berliner eurosun geboerdelte lamelle"
                />
              </div>
              <div class="text-lg self-center w-full md:w-2/3">
                <span class="font-bold"
                  >Randgebördelte Lamellen in den Breiten 60 mm und 80 mm</span
                >
                <br />
                Der Klassiker unter den Außenraffstores. Die Lamellen sind
                zwischen den Querstegen der Leiterkordeln eingeschoben,
                stegklammern fixieren die Querstege.
              </div>
            </li>
            <li class="flex flex-col md:flex-row p-4">
              <div class="p-1 w-full md:w-1/3">
                <img
                  src="../assets/img/eurosun/außenraffstore/eurosun-s-lamelle.webp"
                  alt=" berliner außenrauffstore eurosun s-lamelle"
                />
              </div>
              <div class="text-lg self-center w-full md:w-2/3">
                <span class="font-bold"
                  >S-Förmige Lamelle mit im Randbördel eingewalztem
                  Dämpfungskeder mit einer Breite von 75 mm</span
                >
                <br />
                Zeitlose Eleganz kombiniert mit hervorragendem Lichtschutz.
                Dieser Raffstore-Typ DES75 kann problemlos gegen Raffstores mit
                80mm breiten Bördel-Lamellen ausgetauscht werden.
              </div>
            </li>
            <li class="flex flex-col md:flex-row p-4">
              <div class="p-1 w-full md:w-1/3">
                <img
                  src="../assets/img/eurosun/außenraffstore/eurosun-z-lamelle.webp"
                  alt=" berliner außenraffstore eurosun z-lamelle"
                />
              </div>
              <div class="text-lg self-center w-full md:w-2/3">
                <span class="font-bold"
                  >Z-förmige Lamellen mit im Randbördel eingewalztem
                  Dämpfungskeder in den Breiten 90 mm</span
                >
                <br />
                Außenraffstores mit dieser Lamellenform bieten den besten
                Lichtschutz im Raum.
              </div>
            </li>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              Eurosun - Außenraffstore ist in verschiedenen Farben und
              Ausführungen erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },
  methods: {},
};
</script>
<style>
button:focus {
  color: #f27405;
}
button::-moz-focus-inner {
  border: 0;
}
</style>
